/**
 * TILT
 **/

import "tilt.js";

if($(".tilt").length) {
    $(".tilt").tilt({
        glare: true,
        maxGlare: 0.4
    });
}
